<template>
    <div style="width: 100%;height: 100%">
        <!--        <iframe ref="iframe" src="http://service.guocode.com/jr" width="100%" height="100%"></iframe>-->

        <div id="fullscreen" @click="handleFullScreen" v-if="fullscreenState===true">
            <svg-icon icon-class="fullscreen-icon" class="fullscreen-icon-class"></svg-icon>
        </div>
        <iframe v-if="showState" ref="iframe" id="frameJr" :src="url" width="100%" height="100%"></iframe>

        <!--        <iframe ref="iframe" src="http://localhost:8601/" width="100%" height="100%" marginheight="1"-->
        <!--                marginwidth="1" hspace="1" vspace="1" scrolling="no"></iframe>-->
        <!--        <div class="load-show">-->
        <div class="iframe-load-z-index" v-if="state">
            <img class="load-gif" src="https://storage.shubenji.cn/GuoBao/game/lottie.gif" alt="">
        </div>

        <!--        </div>-->
    </div>
</template>

<script>
import { full } from "@/views/ipad/common";
import TeacherUser from "@/models/Home/TeacherUser";
import axios from "axios";
import uuid from "@/utils/uuid";

export default {
    name: "ScratchJrIframe",
    data() {
        return {
            state: true,
            showState: false,
            fullscreenState: false,
            // url: 'http://192.168.50.193:8080/pro/'
            url: '',
        }
    },
    created() {
        // if (this.$route.query.url !== '') {
        //     this.url = this.$route.query.url;
        // }
        if (localStorage.getItem('info') !== null) {
            // 服务端拿的 token 可以从 vuex、localStorage 等地方取
            axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
        } else {
            axios.defaults.headers['token'] = ``;
        }
    },
    mounted() {
        console.log(history)
        this.homeBasisInfo();
        window.addEventListener('message', (e) => {
            let res = e.data;
            console.log(res)
            /**关闭加载层*/
            if (res.name === 'loadHide') {
                this.state = false;
                setTimeout(() => {
                    if (this.url.indexOf('guobaojr') > -1) {
                        this.fullscreenState = false;
                    } else {
                        this.fullscreenState = true;
                    }
                }, 0)
            }
            /**fullscreen*/
            if (res.name === 'fullscreen') {
                this.handleFullScreen();
            }

            /**returnNext*/
            if (res.name === 'returnNext') {
                this.$router.push({ path: '/ipad/home' })
            }
        })
        this.url = this.$route.query.url;

        if (this.$route.query.stuLessonId !== undefined) {
            let url = this.url + "?stuLessonId=" + this.$route.query.stuLessonId + '&aa=1';
            this.url = url;
            this.showState = true;
        } else if (this.$route.query.workId !== undefined) {
            let url = this.url + "?workId=" + this.$route.query.workId + '&aa=1';
            this.url = url;
            this.showState = true;
        } else {
            this.showState = true;
        }

        // 监听返回事件,点击系统返回时
        // if(window.history && window.history.pushState){
        //     window.addEventListener('popstate',this.backChange,false)
        // }
        // window.addEventListener("popstate", function(e) {
        //     alert("我监听到了浏览器的返回按钮事件啦");//根据⾃⼰的需求实现⾃⼰的功能
        // }, false);
    },

    methods: {
        //首页基本信息
        homeBasisInfo() {
            TeacherUser.getBasisInfo({}, ({ type, list }) => {
                if (type === 200) {
                    // this.homeBasisData = list;
                    // this.nickname = list.userinfo.nickname;
                    // this.avatarImg = list.userinfo.user_avatar;
                    localStorage.setItem("goodsCategory", list.bureau.goods_category);
                }
            })
        },
        // 监听返回事件
        backChange() {
            // this.$router.go(2)
            // location.reload() //强制刷新页面 ，也可以做其他操作
        },
        handleFullScreen() {
            full.Fullscreen("#app");
            const mapFrame = this.$refs.iframe;
            const iframeWin = mapFrame.contentWindow;
            iframeWin.postMessage(
                {
                    value: 'saveStage',
                    id: 'vue',
                    success: true
                },
                '*'
            );

            // var cWindow = document.frames
            // var div=cWindow .document.getElementById('stage');
            // console.log(window.parent.document.getElementsByTagName("iframe")[0].style);
            // let iframeDocument = document.getElementsByTagName('iframe')[1].contentDocument;
            // let myDOM = iframeDocument.getElementById('frame');
            //
            // console.log(myDOM)
            // document.getElementById('stage').onload = function () { //通过inload事件监测iframe的加载
            //     document.getElementById('stage') //获取到iframe
            //         .contentWindow //获取到iframe的window
            //         .document //获取到iframe的document
            //         .getElementById('stage') //获取iframe中id=‘xoc’的DOM元素
            //         .style = {
            //         webkitTransform: 'translate(' + (-65) + '%, ' + (-55) + '%) ' +
            //             'scale(' + 0.8 + ') ' +
            //             'translate(' + (100) + 'px, ' + (200) + 'px)'
            //     }
            // }
        },
        sendMesFroIframe() {
            // 向iframe传值
            setTimeout(() => {
                const mapFrame = this.$refs.iframe;
                const iframeWin = mapFrame.contentWindow;
                iframeWin.postMessage(
                    {
                        value: 'backSuccess',
                        id: 'vue',
                        success: true
                    },
                    '*'
                );
            }, 0)

        },
    },
    destroyed() {
        //销毁监听
        window.removeEventListener('popstate', this.backChange, false)
    },
}
</script>

<style lang="less" scoped>
iframe {
    border-width: 0px !important;
}

.iframe-load-z-index {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.load-gif {
    width: 10rem;
    height: 10rem;
}

#fullscreen {
    position: fixed;
    width: 4rem;
    height: 4rem;
    right: 20rem;
    top: 1rem;

    .fullscreen-icon-class {
        width: 3rem;
        height: 3rem;
        color: #333333;
    }
}
</style>
